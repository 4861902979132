
            <div class="card" *ngIf="vehicle.mileage || vehicle.manufactureDate|| vehicle.power || vehicle.fuelType || vehicle.transmissionType">
                <div class="card-body">
                    <div *ngIf="vehicle.mileage" class="row icon-list mt-2 mb-2">
                        <div class="item__title col-7 d-flex">
                            <div class="icon-wrapper accent">
                                <fa-icon [icon]="faRoad"></fa-icon>
                            </div>
                            <div class="mt-auto mb-auto">{{ 'Page_Details_KmDriven' | translate }}</div>
                        </div>
                        <div class="item__value col-5 d-flex"><span class="mt-auto mb-auto">{{vehicle?.mileage |
                                number:'1.0':'en-us' | replace:',':' '}} km</span></div>
                    </div>
                    <div *ngIf="vehicle.manufactureDate" class="row icon-list mt-2 mb-2">
                        <div class="item__title col-7 d-flex">
                            <div class="icon-wrapper accent">
                                <fa-icon [icon]="faCalendar"></fa-icon>
                            </div>
                            <div class="mt-auto mb-auto">{{ 'Page_Details_ManufactureDate' | translate }}</div>
                        </div>
                        <div class="item__value col-5 d-flex"><span class="mt-auto mb-auto">{{vehicle?.manufactureDate |
                                date:'MM/yyyy'}}</span></div>
                    </div>
                    <div *ngIf="vehicle.power" class="row icon-list mt-2 mb-2">
                        <div class="item__title col-7 d-flex">
                            <div class="icon-wrapper accent">
                                <fa-icon [icon]="faBolt"></fa-icon>
                            </div>
                            <div class="mt-auto mb-auto">{{ 'Page_Details_Power' | translate }}</div>
                        </div>
                        <div class="item__value col-5 d-flex"><span class="mt-auto mb-auto">{{vehicle?.power | number:'1.0-0'}}kW /
                                {{vehicle?.power * kwToHpRatio | number:'1.0-0'}}{{ 'Page_Details_HP' | translate}}</span>
                        </div>
                    </div>
                    <div *ngIf="vehicle.fuelType" class="row icon-list mt-2 mb-2">
                        <div class="item__title col-7 d-flex">
                            <div class="icon-wrapper accent">
                                <fa-icon [icon]="faGasPump"></fa-icon>
                            </div>
                            <div class="mt-auto mb-auto">{{ 'Page_Details_Fuel' | translate }}</div>
                        </div>
                        <div class="item__value col-5 d-flex"><span class="mt-auto mb-auto">{{vehicle?.fuelType |
                                idToName:(globalService.fuelTypes$ | async) | translate}}</span></div>
                    </div>
                    <div *ngIf="vehicle.transmissionType" class="row icon-list mt-2 mb-2">
                        <div class="item__title col-7 d-flex">
                            <div class="icon-wrapper accent">
                                <fa-icon [icon]="faCogs"></fa-icon>
                            </div>
                            <div class="mt-auto mb-auto">{{ 'Page_Details_Transmission' | translate }}</div>
                        </div>
                        <div class="item__value col-5 d-flex"><span class="mt-auto mb-auto">{{vehicle?.transmissionType |
                                idToName:(globalService.gearboxTypes$ | async) | translate}}</span></div>
                    </div>
                </div>
            </div>