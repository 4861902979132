<h3 class="m-3 col-12">{{ 'Page_Details_Details' | translate }}</h3>
<div class="mt-3">
    <div class="card p-xl-1">
        <div class="card-body">
            <h5 class="card-title">{{ 'Page_Details_DetailsTitle' | translate | uppercase }}</h5>

            <div class="row">
                <div class="col-12 col-xl-6 custom__rows_with_margin">
                    <ng-container *ngIf="vehicle.engineVolume">
                        <div class="row">
                            <div class="item__title col-6 small text-left">{{ 'Page_Details_EngineVolume' | translate }}
                            </div>
                            <div class="item__value col-6 small font-weight-bolder">{{ vehicle?.engineVolume }}
                                cm<sup>3</sup></div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="vehicle.drivenWheelsTypeId">
                        <hr class="mt-2 mb-2 d-none d-xl-block" />
                        <div class="row">
                            <div class="item__title col-6 small text-left">{{ 'Page_Details_DrivenWheelsType' | translate }}
                            </div>
                            <div class="item__value col-6 small font-weight-bolder">{{ vehicle?.drivenWheelsTypeId |
                                idToName:(globalService.drivenWheels$ | async) | translate}}</div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="vehicle.noOfDoorsId">
                        <hr class="mt-2 mb-2 d-none d-xl-block" />
                        <div class="row">
                            <div class="item__title col-6 small text-left">{{ 'Page_Details_NoOfDoors' | translate }}</div>
                            <div class="item__value col-6 small font-weight-bolder">{{ vehicle?.noOfDoorsId |
                                idToName:(globalService.doorsTypes$ | async) | translate}}</div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="vehicle.seatCount">
                        <hr class="mt-2 mb-2 d-none d-xl-block" />
                        <div class="row">
                            <div class="item__title col-6 small text-left">{{ 'Page_Details_SeatCount' | translate }}</div>
                            <div class="item__value col-6 small font-weight-bolder">{{ vehicle?.seatCount }}</div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="vehicle.sdkCode">
                        <hr class="mt-2 mb-2 d-none d-xl-block" />
                        <div class="row">
                            <div class="item__title col-6 small text-left">{{ 'Page_Details_SDKCode' | translate }}</div>
                            <div class="item__value col-6 small font-weight-bolder">{{ vehicle?.sdkCode }}</div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="vehicle?.warrantyPeriod && vehicle?.warrantyMileage > 0">
                        <hr class="mt-2 mb-2 d-none d-xl-block" />
                        <div class="row">
                            <div class="item__title col-6 small text-left">{{ 'Page_Details_WarrantyPeriod' | translate }}</div>
                            <div class="item__value col-6 small font-weight-bolder">
                                <span *ngIf="vehicle?.warrantyPeriod">{{ vehicle?.warrantyPeriod }} {{
                                    'Component_CarCard_Months' | translate }}</span>
                                <span *ngIf="vehicle?.warrantyPeriod && vehicle?.warrantyMileage > 0"> / </span>
                                <span *ngIf="vehicle?.warrantyMileage > 0">{{ vehicle?.warrantyMileage }} km</span>
                            </div>
                        </div>
                    </ng-container>
                </div>

                <div class="col-12 col-xl-6 custom__rows_with_margin">
                    <ng-container *ngIf="vehicle.bodyTypeId">
                        <div class="row">
                            <div class="item__title col-6 small text-left">{{ 'Page_Details_BodyType' | translate }}</div>
                            <div class="item__value col-6 small font-weight-bolder">{{ vehicle?.bodyTypeId |
                                idToName:(globalService.bodyTypes$ | async) | translate}}</div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="vehicle.colorId">
                        <hr class="mt-2 mb-2 d-none d-xl-block" />
                        <div class="row">
                            <div class="item__title col-6 small text-left">{{ 'Page_Details_Color' | translate }}</div>
                            <div class="item__value col-6 small font-weight-bolder">{{ vehicle?.colorId |
                                idToName:(globalService.colors$ | async) | translate}}</div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="vehicle.climateControlTypeId">
                        <hr class="mt-2 mb-2 d-none d-xl-block" />
                        <div class="row">
                            <div class="item__title col-6 small text-left">{{ 'Page_Details_ClimateControlType' | translate
                                }}</div>
                            <div class="item__value col-6 small font-weight-bolder">{{ vehicle?.climateControlTypeId |
                                idToName:(globalService.climateControls$ | async) | translate}}</div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="vehicle.firstRegistrationCountryId">
                        <hr class="mt-2 mb-2 d-none d-xl-block" />
                        <div class="row">
                            <div class="item__title col-6 small text-left">{{ 'Page_Details_FirstRegistrationCountry' | translate }}
                            </div>
                            <div class="item__value col-6 small font-weight-bolder">{{ vehicle?.firstRegistrationCountryId |
                                idToName:(globalService.countries$ | async) | translate}}</div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="vehicle.cO2Emission">
                        <hr class="mt-2 mb-2 d-none d-xl-block" />
                        <div class="row">
                            <div class="item__title col-6 small text-left">{{ 'Page_Details_CO2Emission' | translate }}</div>
                            <div class="item__value col-6 small font-weight-bolder">{{ vehicle?.cO2Emission }} g/km</div>
                        </div>
                    </ng-container>
                </div>
            </div>

        </div>
    </div>
</div>